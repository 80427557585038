import React from 'react';

const Pig: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12C10 11.4477 10.4477 11 11 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H11C10.4477 13 10 12.5523 10 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2C12.4477 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2ZM10 3C10 1.34315 11.3431 0 13 0C14.6569 0 16 1.34315 16 3C16 4.65685 14.6569 6 13 6C11.3431 6 10 4.65685 10 3Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C10.5523 20 11 20.4477 11 21V23C11 23.5523 10.5523 24 10 24C9.44772 24 9 23.5523 9 23V21C9 20.4477 9.44772 20 10 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 20C16.5523 20 17 20.4477 17 21V23C17 23.5523 16.5523 24 16 24C15.4477 24 15 23.5523 15 23V21C15 20.4477 15.4477 20 16 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62081 6.99997H16.5C20.6423 6.99997 24 10.3577 24 14.5C24 18.6423 20.6423 22 16.5 22H9.5C6.62109 22 4.12397 20.3773 2.86722 18H1C0.447715 18 0 17.5523 0 17V12C0 11.4477 0.447715 11 1 11H2.87249C3.34008 10.1151 3.97776 9.33374 4.74566 8.70195L4.03992 6.2797C3.89105 5.76874 4.16845 5.23071 4.67103 5.05563L5 5.99997C4.67103 5.05563 4.67155 5.05545 4.67208 5.05527L4.67319 5.05488L4.67562 5.05404L4.68128 5.05211L4.69574 5.04729C4.70677 5.04367 4.72056 5.03925 4.73697 5.0342C4.76977 5.0241 4.81323 5.01142 4.86626 4.99747C4.97201 4.96966 5.11759 4.93635 5.29394 4.90863C5.64306 4.85376 6.13355 4.81748 6.68381 4.90243C7.70432 5.05996 8.83846 5.62593 9.62081 6.99997ZM6.29438 6.86764C6.32228 6.87087 6.3504 6.87465 6.37869 6.87901C6.90598 6.96041 7.59889 7.25341 8.07887 8.38922C8.2353 8.7594 8.59813 8.99997 9 8.99997H16.5C19.5377 8.99997 22 11.4623 22 14.5C22 17.5377 19.5377 20 16.5 20H9.5C7.2149 20 5.25313 18.6049 4.4229 16.6149C4.26753 16.2425 3.90354 16 3.5 16H2V13H3.505C3.90814 13 4.27185 12.7579 4.42748 12.386C4.8498 11.3769 5.55831 10.5192 6.45506 9.92078C6.81825 9.67842 6.98222 9.22845 6.86008 8.80924L6.29438 6.86764Z"
      />
    </svg>
  );
};

export default Pig;
